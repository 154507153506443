import { render, staticRenderFns } from "./clubs.vue?vue&type=template&id=7049e8af&scoped=true&"
import script from "./clubs.vue?vue&type=script&lang=js&"
export * from "./clubs.vue?vue&type=script&lang=js&"
import style0 from "./clubs.vue?vue&type=style&index=0&id=7049e8af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7049e8af",
  null
  
)

export default component.exports