import { render, staticRenderFns } from "./clubRevenues.vue?vue&type=template&id=09c1ccbf&scoped=true&"
import script from "./clubRevenues.vue?vue&type=script&lang=js&"
export * from "./clubRevenues.vue?vue&type=script&lang=js&"
import style0 from "./clubRevenues.vue?vue&type=style&index=0&id=09c1ccbf&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09c1ccbf",
  null
  
)

export default component.exports