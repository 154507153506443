<template>
  <div :style="{'width': '100%'}" class="d-flex ml-auto">
    <div
      class="box"
      style="width:100%;position:relative"
      @mouseenter="focusOnSearchBar()"
      @click="focusOnSearchBar()"
      @mouseleave="blur()"
    >
      <input
        ref="txt"
        v-model="search"
        @keyup.enter="$emit('enter')"
        type="text"
        class="input"
        :class="{'paddingOnFocus' : focused }"
        :style="focused ? `width:100%` : 'fillernonsense'"
        style="transition:all .2s ease;max-width:100%;"
        @click="focusOnSearchBar()"
      >
      <i
        ref="question"
        style="position:absolute"
        class="fas fa-search cp question"
        @click="focusOnSearchBar()"
      />
      <i
        style="position: absolute;"
        :class="{'fade-in': focused }"
        class="fas fa-times cp clear fade"
        @click="clearEntered()"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ExpandableSearchBar',
  components: {
  },
  props: {
    width: {
      default: 300,
      type: Number
    },
    maxWidthpercentage: {
      default: 65,
      type: Number
    }
  },
  data () {
    return {
      search: '',
      focused: true
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  watch: {
    search () {
      this.$emit('search', this.search.toLowerCase())
    }
  },
  methods: {
    clearEntered () {
      this.search = ''
      this.blur()
    },
    focusOnSearchBar () {
      this.$refs.txt.focus()
      this.focused = true
    },
    blur () {
      if (this.search === '') {
        this.$refs.txt.blur()
      }
    }
  },
  beforeUnmount () {
    this.$emit('search', '')
  }
}
</script>

<style lang="scss" scoped>
.box {
  position:relative;
}
input[type=text]:focus:not([readonly]) {
  box-shadow: none;
  border: 3px solid var(--pr-color);
}
.input {
  height: 40px;
  width: 40px;
  background: none;
  border: 3px solid var(--pr-color);
  border-radius: 50px;
  box-sizing: border-box;
  background: transparent;
  color: #555;
  outline: none;
  transition: .5s;
  transform-origin: right;
  font-size: 14px;
  letter-spacing: 1px;
  // color: transparent;
  color: #555;
}
.paddingOnFocus {
  padding: 0 30px;
}
input:focus {
  transform-origin: right;
  border-radius: 20px;
}

i.question, i.clear {
  position: absolute;
  top: 32%;
  font-size: 15px;
  color: #555;
  transition: .2s;
}
i.question {
  left: 12px;
}
i.clear {
  right: 14px;
}
.fade {
  opacity: 0;
  transition: .8s ease;
}
.fade.fade-in {
  opacity: 1;
  transition: .8s ease;
}
.widthNoFocus {
  width: 40px;
}

@media (max-width: 768px) {
  input[type=text]:focus:not([readonly]) {
    border: 2px solid var(--pr-color);
  }
  .input {
    width: 30px;
    height: 30px;
    border: 2px solid var(--pr-color);
    font-size: 12px;
  }
  i.question, i.clear {
    top: 30%;
    font-size: 13px;
  }
  i.clear {
    right: 12px;
  }
  i.question {
    left: 9px;
  }
  .paddingOnFocus {
    padding: 0 25px 0 25px;
  }
}
</style>
