<template>
  <mdb-modal
    id="toggleSuspendAccount"
    class="black-text"
    :show="resetPasswordModal"
    @close="$emit('resetPasswordModal', false)"
  >
    <mdb-modal-header class="modal-custom-header">
      <mdb-modal-title>
        CONFIRM
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="modal-custom-body">
      <p class="my-4" style="border-top:1px solid white;margin-top:-10px !important;" />
      <p class="my-4" style="text-align:left;color:#1FFC35 !important;">
        Are you sure you wish to reset the password for account holder {{ emailOfAccountToReset }}?
      </p>
    </mdb-modal-body>
    <mdb-modal-footer class="modal-custom-footer">
      <mdb-btn
        v-if="!loading"
        style="width:120px;margin:0 !important;font-size:0.8rem !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        color="primary"
        @click="confirm()"
      >
        CONFIRM
      </mdb-btn>
      <mdb-btn
        v-else
        style="width:120px;margin:0 !important;font-size:0.8rem !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        color="primary"
      >
        <i class="fa fa-spinner fa-pulse" />
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'ResetPasswordModal',
  props: {
    emailOfAccountToReset: {
      type: [String],
      default: function () {
        return ''
      }
    },
    DD: {
      type: [String],
      default: function () {
        return ''
      }
    },
    MM: {
      type: [String],
      default: function () {
        return ''
      }
    },
    YYYY: {
      type: [String],
      default: function () {
        return ''
      }
    },
    resetPasswordModal: Boolean
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ])
  },
  methods: {
    async confirm () {
      this.loading = true
      try {
        const res = await adminService.adminResetPassword(this.emailOfAccountToReset, this.DD, this.MM, this.YYYY, this.userData.access_token)
        if (res.data.Message === 'Password successfully reset. Please check your emails') {
          this.$emit('refreshPlayersTable')
          this.$emit('resetPasswordModal', false)
        }
      } catch (err) { console.log(err) }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
