<template>
  <div
    class="primary-colour"
    style="margin-top: 20px;"
  >
    <div
      v-for="(type, i) in dataToShow"
      :key="i" 
      class="p-3 mb-2 d-flex justify-content-center"
      style="font-size: clamp(1rem, 2vw, 2rem);width: 100%; border: solid 2px var(--pr-color);margin-bottom:20px !important;"
    >
      <span>{{ type.text }}:</span>
      <span class="text-white font-weight-bold ml-2">£{{ type.amount }}</span>
    </div>
    *for current year
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import baseService from '@/api-services/base.service'

export default {
  name: 'RevenueBreakdown',
  data () {
    return {
      prize: 0.00,
      monthlyPrize: 0.00
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    dataToShow () {
      return [
        { text: 'Total Revenue*', amount: Number(this.prize) * 5 },
        { text: 'Club Revenue*', amount: Number(this.prize) * 2 },
        { text: 'GRL Revenue*', amount: Number(this.prize) * 2 },
        { text: 'Prize Pot*', amount: Number(this.prize) },
        { text: 'Active Monthly Draws*', amount: Number(this.monthlyPrize) }
      ]
    }
  },
  created () {
    this.privatePrizePot()
  },
  methods: {
    privatePrizePot () {
      baseService.privatePrizePot(this.userData.access_token).then((results) => {
        if (results.data >= 50) {
          const interval = Math.floor(results.data / 50)
          const incrementInterval = setInterval(() => {
            if (this.prize < results.data.TotalPrizePot) {
              this.prize += interval
            } else {
              this.prize = results.data.TotalPrizePot
              clearInterval(incrementInterval)
            }
          }, 30)
        } else {
          this.prize = results.data.TotalPrizePot
        }

        this.monthlyPrize = results.data.MonthlyPrizePot
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
