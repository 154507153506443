<template>
  <div class="primary-colour mt-3 mt-md-4 mb-5" style="width:100%;margin-bottom: -30px !important;">
    <div
      v-if="players != null"
      class="playersTable mt-3 mt-md-4 mb-5"
    >
      <BTable
        id="players-table"
        :items="players"
        class="text-left"
        style="overflow-y: auto;"
        head-variant="dark"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        responsive
        :striped="true"
        hover
        small
        sort-icon-left
        auto-width
      >
        <template v-slot:cell(emailaddress)="data">
          <div
            style="margin-top:12px;"
            :style="{ 'color': data.item.Role === 'Super User' ? '#1FFC35 !important' : '#ffffff' }"
          >
            {{ data.item.EmailAddress }}
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <div
            style="margin-top:12px;"
            :style="{ 'color': data.item.Role === 'Super User' ? '#1FFC35 !important' : '#ffffff' }"
          >
            {{ data.item.FirstName }} {{ data.item.LastName }}
          </div>
        </template>
        <template v-slot:cell(role)="data">
          <div 
            style="margin-top:12px;"
            :style="{ 'color': data.item.Role === 'Super User' ? '#1FFC35 !important' : '#ffffff' }"
          >
            {{ data.item.Role }}
          </div>
        </template>
        <template v-slot:cell(buttons)="data">
          <div
            v-if="!hidePopover"
            class="d-flex align-items-center justify-content-center pt-0"
            style="margin-top:2px;"
          >
            <mdb-popover
              ref="popover"
              trigger="click"
              :options="{placement: 'left'}"
              style="cursor:pointer;"
            >
              <div
                slot="body"
              >
                <div
                  v-if="data.item.Role !== 'Super User'"
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;"
                  @click="editPlayer(data.item.EmailAddress)"
                >
                  <i class="fa fa-edit mr-2" style="color:#555 !important;font-size:0.8rem;" />EDIT
                </div>
                <div
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;"
                  @click="viewTransactionRecords(data.item.EmailAddress)"
                >
                  <i class="fa fa-credit-card mr-2" style="color:#555 !important;font-size:0.8rem;" />TRANSACTIONS
                </div>
                <div
                  v-if="data.item.Role !== 'Super User'"
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;"
                  @click="resetPassword(data.item.EmailAddress, data.item.DD, data.item.MM, data.item.YYYY)"
                >
                  <i class="fa fa-unlock-alt mr-2" style="color:#555 !important;font-size:0.8rem;" />RESET PASSWORD
                </div>
                <div
                  v-if="data.item.Role !== 'Super User'"
                  class="d-flex align-items-center m-0 mb-1"
                  style="color:#555 !important;font-size:0.8rem;"
                  @click="closeAccount(data.item.EmailAddress)"
                >
                  <i class="fa fa-times-circle mr-2" style="color:#555 !important;font-size:0.8rem;" />CLOSE ACCOUNT
                </div>
              </div>
              <mdb-btn
                slot="reference"
                style="background-color: none !important;"
                class="px-2 m-0 btn-blank d-flex align-items-center justify-content-center align-self-end"
              >
                <i class="fa fa-ellipsis-v primary-colour" />
              </mdb-btn>
            </mdb-popover>
          </div>
        </template>
      </BTable>
      <div v-if="players.length > perPage" style="width:100%;">
        <b-pagination
          v-show="players.length > perPage"
          v-model="currentPage"
          class="ml-auto mt-4"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          aria-controls="players-table"
          prev-text="<"
          next-text=">"
        />
      </div>
    </div>
    <div v-else class="primary-colour font-weight-bold deskAlign">
      Your search returned no results
    </div>

    <CloseAccountModal
      :email-of-account-to-close="emailOfAccountToClose"
      :close-account-modal="closeAccountModal"
      @refreshPlayersTable="$emit('refreshPlayersTable')"
      @closeAccountModal="closeAccountModal = $event"
    />

    <ResetPasswordModal
      :email-of-account-to-reset="emailOfAccountToResetPassword"
      :reset-password-modal="resetPasswordModal"
      :d-d="DDToReset"
      :m-m="MMToReset"
      :y-y-y-y="YYYYToReset"
      @refreshPlayersTable="$emit('refreshPlayersTable')"
      @resetPasswordModal="resetPasswordModal = $event"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseAccountModal from './closeAccount.vue'
import ResetPasswordModal from './resetPassword.vue'

export default {
  name: 'PlayersTable',
  components: { CloseAccountModal, ResetPasswordModal },
  props: {
    players: {
      type: Array,
      default: () => { }
    }
  },
  data () {
    return {
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      perPage: 8,
      userLanguage: navigator.language,
      windowWidth: window.innerWidth,
      toggleSuspendAccountModal: false,
      currentlySuspended: false,
      emailToSuspend: '',
      emailOfAccountToClose: '',
      closeAccountModal: false,
      resetPasswordModal: false,
      emailOfAccountToResetPassword: '',
      DDToReset: '',
      MMToReset: '',
      YYYYToReset: '',
      hidePopover: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ]),
    fields () {
      if (this.windowWidth > 576) {
        return [
          { key: 'Name', label: 'Name' },
          { key: 'EmailAddress', label: 'Email', sortable: true },
          { key: 'Role', label: 'Role' },
          { key: 'Buttons', label: '' }
        ]
      } else {
        return [
          { key: 'Name', label: 'Name' },
          { key: 'EmailAddress', label: 'Email', sortable: true },
          { key: 'Buttons', label: '' }
        ]
      }
    },
    rows () {
      try {
        return this.players.length
      } catch { console.log('error at rows - computed value'); return 0 }
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    },
    viewTransactionRecords (email) {
      this.$emit('playerTransactionRecords', { email })
      this.resetPopover()
    },
    editPlayer (email) {
      this.$emit('editPlayer', { email })
      this.resetPopover()
    },
    resetPopover () {
      this.hidePopover = true
      this.$nextTick(() => {
        this.hidePopover = false
      })
    },
    resetPassword (email, DD, MM, YYYY) {
      this.emailOfAccountToResetPassword = email
      this.DDToReset = String(DD)
      this.MMToReset = String(MM)
      this.YYYYToReset = String(YYYY)
      this.resetPasswordModal = true
      this.resetPopover()
    },
    closeAccount (email) {
      this.emailOfAccountToClose = email
      this.closeAccountModal = true
      this.resetPopover()
    }
  }
}
</script>

<style lang="scss">
.pagination button[aria-checked=true] {
  background: var(--pr-color) !important;
}
.playersTable * {
  font-size: 0.85rem !important;
}
.playersTablePopover * {
  font-size: 0.85rem !important;
  color: var(--bg-color);
}
.deskAlign {
    margin-top: -56px !important;
  }
@media (max-width: 576px) {
  .deskAlign {
    margin-top: 0px !important;
  }
  .playersTop {
    margin-top: 11px !important;
  }
}
</style>
