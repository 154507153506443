<template>
  <div style="width:100%;">
    <div
      style="text-align:center;width:100%;margin-top:20px;margin-bottom:20px !important;"
      class="mb-3"
    >
      {{ clubId > '0' ? 'Edit' : 'New' }} Club Details for {{ newClub.cnm }}
    </div>
    <LogoUpload
      v-if="clubId > 0"
      :club-id="cid"
      :logo-src="newClub.pic" 
      @logoUploaded="getClub()"
    />
    <validation-observer>
      <form style="width:100%" autocomplete="off">
        <div class="md-form">
          <div class="md-form form-sm">
            <validation-provider
              mode="lazy"
              name="Club Name"
              rules="required"
            >
              <i class="fas fa-shield-alt prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.cnm"
                type="text"
                auto-complete="nc-cnm"
                label="Club name"
                style="margin-left:33px;max-width:100%;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Address"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.adr"
                type="text"
                auto-complete="nc-adr"
                label="Address"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Post Code"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.pce"
                type="text"
                auto-complete="nc-pce"
                label="Post code"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Forename"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.fnm"
                type="text"
                auto-complete="nc-fnm"
                label="Club contact"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Surname"
              rules="required"
            >
              <i class="fas fa-user prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.lnm"
                type="text"
                auto-complete="nc-lnm"
                label="Last name"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Email"
              rules="required"
            >
              <i class="fas fa-envelope prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.eml"
                type="email"
                auto-complete="nc-eml"
                label="Main contact email"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Telephone"
              rules="required"
            >
              <i class="fas fa-phone prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.tel"
                type="text"
                auto-complete="nc-eml"
                label="Main contact telephone"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:35px;">
            <validation-provider
              mode="lazy"
              name="Local lottery licence number"
              rules="required"
            >
              <i class="fa fa-ticket-alt prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.lll"
                type="text"
                auto-complete="nc-lll"
                label="Local lottery licence number"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
          </div>
          <div class="md-form form-sm" style="margin-top:30px;">
            <validation-provider
              mode="lazy"
              name="Club url value"
              rules="required"
            >
              <i class="fa fa-link prefix sm" style="left:0;margin-top:9px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.url"
                type="text"
                auto-complete="nc-url"
                label="Club url value"
                style="margin-left:33px;"
                size="sm"
              />
            </validation-provider>
            <div class="md-form form-sm" style="margin-top:20px;">
              <i class="fa fa-palette sm" style="left:0;margin-top:18px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.acc"
                type="text"
                auto-complete="nc-acc"
                disabled
                label="Accent Colour"
                style="margin-left:33px;margin-top: -30px;"
                size="sm"
              />
            </div>
            <div class="md-form form-sm" style="margin-top:20px;">
              <i class="fa fa-shield-alt sm" style="left:0;margin-top:18px;font-size:1rem;" />
              <mdb-input
                v-model="newClub.aff"
                type="text"
                auto-complete="nc-aff"
                disabled
                label="Affiliate"
                style="margin-left:33px;margin-top: -30px;"
                size="sm"
              />
            </div>
          </div>
          <div
            class="md-form form-sm"
            style="margin-top:25px;margin-bottom: 10px !important;"
            @click="toggleMonthlyDraw()"
          >
            <mdb-input
              v-model="newClub.drw"
              type="checkbox"
              auto-complete="ex-drw"
              style="margin-left:-20px;"
              size="sm"
            />
            <div style="margin-left: 33px;margin-top: -15px;font-size: small;">
              Entered into monthly draw (<span class="primary-colour">currently {{ newClub.ply }} playing</span>)?
            </div>
          </div>
          <div
            class="md-form form-sm"
            style="margin-top:25px;margin-bottom: 10px !important;"
            @click="toggleAgree()"
          >
            <mdb-input
              v-model="agree"
              type="checkbox"
              auto-complete="ex-agr"
              style="margin-left:-20px;"
              size="sm"
            />
            <div style="margin-left: 33px;margin-top: -15px;font-size: small;">
              Are you sure you would like to {{ clubId > '0' ? 'change' : 'save' }} the club details (and for a newly signed-up club, enable them)?
            </div>
          </div>
        </div>
      </form>
    </validation-observer>
    <div style="margin-top:35px !important;padding-bottom: 20px;" class="d-flex flex-column mr-4 mr-md-5">
      <mdb-btn
        v-show="!buttonClick"
        :disabled="agree === false"
        style="width:110px;margin:0 !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        size="sm"
        @click="addEditClubDetails()"
      >
        SAVE
      </mdb-btn>
      <mdb-btn
        v-show="buttonClick"
        style="width:110px;margin:0 !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        size="sm"
      >
        <mdb-icon icon="fa fa-pulse fa-spinner" />
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'
import LogoUpload from '@/components/logoUpload.vue'

export default {
  name: 'AddEditClubDetails',
  components: { LogoUpload },
  props: {
    cid: {
      type: [Number],
      default: function () {
        return 0
      }
    },
  },
  data () {
    return {
      newClub: {
        cnm: '',
        adr: '',
        pce: '',
        fnm: '',
        lnm: '',
        eml: '',
        tel: '',
        lll: '',
        pic: '',
        cid: 0,
        udd: 1,
        umm: 1,
        uyy: 1980,
        drw: false,
        url: '',
        aff: '',
        ply: 0,
        acc: '#1FFC35'
      },
      buttonClick: false,
      agree: false
    }
  },
  computed: {
    ...mapGetters([
      'userData'
    ]),
    clubId () {
      return this.cid
    }
  },
  watch: {
    clubId: {
      handler: function () {
        if (this.clubId > 0) {
          this.getClub()
        } else {
          this.newClub = {
            cnm: '',
            adr: '',
            pce: '',
            fnm: '',
            lnm: '',
            eml: '',
            tel: '',
            lll: '',
            pic: '',
            cid: 0,
            udd: 1,
            umm: 1,
            uyy: 1980,
            drw: false,
            url: '',
            aff: '',
            ply: 0,
            acc: '#1FFC35'
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleAgree () {
      this.agree = !this.agree
    },
    toggleMonthlyDraw () {
      this.newClub.drw = !this.newClub.drw
    },
    getClub () {
      if (this.clubId === 0) { return }
      adminService.getClubById(this.clubId, this.userData.access_token).then((results) => {
        this.newClub = { ...this.newClub, ...results.data }
      }).catch((error) => {
        console.log(error)
      })
    },
    addEditClubDetails () {
      this.buttonClick = true
      adminService.addEditClubDetails(this.newClub, this.userData.access_token).then((results) => {
        console.log(results.data)
        this.agree = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'Success', errorMessage: 'Club details updated!' })
        this.buttonClick = false
      }).catch((error) => {
        this.buttonClick = false
        this.$store.commit('GENERIC_ERROR_MODAL', { showing: true, errorTitle: 'Error', errorMessage: Object.assign({}, error).response.data.Message })
        this.agree = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 20px);
}
</style>
