<template>
  <MainContainer :no-left-buttons="true">
    <template #leftPanelTop>
      <AdminOps />
    </template>

    <template #rightPanel>
      <SideMenu />
    </template>
  </MainContainer>
</template>

<script>
import { mapGetters } from 'vuex'
import SideMenu from '@/components/navbar/sideMenu.vue'
import MainContainer from '@/components/layout/mainContainer.vue'
import AdminOps from '@/components/adminops/adminops.vue'

export default {
  name: 'AdminContainer',
  components: {
    MainContainer, SideMenu, AdminOps
  },
  computed: {
    ...mapGetters(['userData'])
  },
  created() {
    if (this.userData.rle !== 'sup') {
      this.$router.push({ path: '/play', query: { page: 1 } })
    }
  },
}
</script>
