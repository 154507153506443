<template>
  <div>
    <div
      v-if="logoSrc"
      class="align-items-center justify-content-center flex-wrap mt-3 mb-4"
      style="width:100%;max-width: 400px; margin-top:0;margin-right:auto;margin-bottom:0;margin-left:auto;background:white;padding:10px;border-radius:10px;"
    >
      <img
        :src="logoSrc"
        style="max-width: 330px;"
        class="mx-auto"
      >
    </div>
    <div
      v-else
      class="mx-auto primary-colour"
      style="width: fit-content;"
    >
      CHOOSE A FILE TO UPLOAD YOUR CLUB CREST
    </div>
    <div class="d-flex align-items-center justify-content-center flex-wrap mt-3 mb-4">
      <mdb-file-input
        v-model="file"
        style="width:100%;max-width: 400px; background: #fff; border-radius: 5px;"
        class="m-0 fileInput"
        type="file"
        size="md"
        @getValue="setFile"
      />
      <mdb-btn
        v-if="file != null"
        class="btn primary-btn btn-right btn-radius text-nowrap m-0 ml-2"
        size="sm"
        @click="clubLogoUpload()"
      >
        UPLOAD
      </mdb-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'ClubLogoUpload',
  components: {
  },
  props: {
    clubId: Number,
    logoSrc: String
  },
  data () {
    return {
      file: null
    }
  },
  computed: {
    ...mapGetters(['userData'])
  },
  methods: {
    setFile (event) {
      const uploadedFile = event[0]
      const convertedFile = new File([uploadedFile], `${this.clubId}-logo`, { type: uploadedFile.type })
      this.file = convertedFile
    },
    async clubLogoUpload () {
      this.loadingCropImage = true
      var formData = new FormData()
      formData.append('file', this.file)
      formData.append('clubId', this.clubId)
      try {
        await adminService.clubLogoUpload(formData, this.userData.access_token)
        this.file = null
        this.$emit('logoUploaded')
      } catch (error) {
        this.file = null
        console.log(error)
      }
    },
  }
}
</script>
<style>
  .fileInput input {
    margin: 10px !important;
  }
  .fileInput > .btn {
    margin: 0 !important; 
    transform: translateY(-10px) !important;
  }
</style>
