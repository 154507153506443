import Axios from 'axios'
import { API_URL } from '@/common/config'

export default class adminService {
  constructor () {
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = API_URL
  }

  static async searchPlayers (search, token) {
    return await Axios.get(API_URL + `/api/game/searchplayers?search=${search}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async addEditClubDetails (clubToEdit, token) {
    return await Axios.post(API_URL + '/api/game/adminaddeditclubdetails', clubToEdit, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getClubById (clubId, token) {
    return await Axios.get(API_URL + `/api/game/getclubbyid?clubId=${clubId}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async toggleTakeABreak (playerEmail, token) {
    return await Axios.post(API_URL + `/api/game/toggletakeabreak?une=${playerEmail}`, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async closeAccount (playerEmail, token) {
    return await Axios.delete(API_URL + `/api/game/closeaccount?une=${playerEmail}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async adminResetPassword (email, DD, MM, YYYY, token) {
    return await Axios.post(API_URL + '/api/game/adminresetpassword?email=' + email + '&DD=' + DD + '&MM=' + MM + '&YYYY=' + YYYY, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async adminGetPlayerDetails (email, token) {
    return await Axios.get(API_URL + '/api/game/admingetplayerdetails?email=' + email, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async editPlayerDetails (playerToEdit, token) {
    return await Axios.post(API_URL + '/api/game/admineditplayerdetails', playerToEdit, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getPaymentsTableData (token) {
    return await Axios.get(API_URL + '/api/game/getpaymentstabledata', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getClubRevenuesByClubId (clubId, token) {
    return await Axios.get(API_URL + `/api/game/getclubrevenuesbyclubid?clubId=${clubId}`, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getClubAdminPlayerList (token) {
    return await Axios.get(API_URL + '/api/game/getclubadminplayerlist', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getClubNumbers (token) {
    return await Axios.get(API_URL + '/api/game/getclubnumbers', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async getClubsByLetters (letters, token) {
    return await Axios.get(API_URL + '/api/game/getclubsbyletters?letters=' + letters, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async hasAdminAccess (token) {
    return await Axios.get(API_URL + '/api/game/hasadminaccess', { headers: { Authorization: 'Bearer ' + token } })
  }

  static async setClubAsPaid (paymentMonth, clubId, token) {
    return await Axios.post(API_URL + `/api/game/setclubaspaid?paymentmonth=${paymentMonth}&clubId=${clubId}`, {}, { headers: { Authorization: 'Bearer ' + token } })
  }

  static async clubLogoUpload (formData, token) {
    return await Axios.post(API_URL + '/api/game/clublogoupload', formData, { headers: { Authorization: 'Bearer ' + token } })
  }  
}
