<template>
  <mdb-modal
    id="toggleSuspendAccount"
    class="black-text"
    :show="closeAccountModal"
    @close="$emit('closeAccountModal', false)"
  >
    <mdb-modal-header class="modal-custom-header">
      <mdb-modal-title>
        CLOSE ACCOUNT
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body class="modal-custom-body">
      <p class="my-4" style="border-top:1px solid white;margin-top:-20px !important;" />
      <p class="my-4" style="text-align:left;color:#1FFC35 !important;">
        <span class="d-block mt-3">
          By closing this account, all personal details will be deleted and the user will no longer be able to access the site. To do so, they will need
          to re-register.
        </span>
        <span class="d-block mt-3">Any pending entries will remain valid, and if this users should win prize money, it will be shared between our partner charities.</span>
        <span class="d-block mt-3">User will be sent confirmation details in an email.</span>
      </p>
    </mdb-modal-body>
    <mdb-modal-footer class="modal-custom-footer">
      <mdb-btn
        v-if="!loading"
        style="width:120px;margin:0 !important;font-size:0.8rem !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        color="primary"
        @click="confirm()"
      >
        CONFIRM
      </mdb-btn>
      <mdb-btn 
        v-else
        style="width:120px;margin:0 !important;font-size:0.8rem !important;"
        class="btn primary-btn btn-radius btn-sm btn-md"
        color="primary"
      >
        <i class="fa fa-spinner fa-pulse" />
      </mdb-btn>
    </mdb-modal-footer>
  </mdb-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import adminService from '@/api-services/admin.service'

export default {
  name: 'CloseAccountModal',
  props: {
    closeAccountModal: Boolean,
    emailOfAccountToClose: {
      type: [String],
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      results: {},
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'mobileView',
      'userData'
    ])
  },
  methods: {
    async confirm () {
      this.loading = true
      try {
        const res = await adminService.closeAccount(this.emailOfAccountToClose, this.userData.access_token)
        if (res.data === true) {
          this.$emit('refreshPlayersTable')
          this.$emit('closeAccountModal', false)
        }
      } catch (err) { console.log(err) }
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
